import * as React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import "./startups_active.module.scss"
import arrow from "../images/arrow.svg"

export default function StartupsActive(props) {
  const data = useStaticQuery(graphql`
    query StartupsActiveQuery {
      allMarkdownRemark(
        filter: { frontmatter: { active: { eq: true } } }
        sort: { fields: frontmatter___sort_order, order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              title
              active
              logo
              sort_order
            }
          }
        }
      }
    }
  `)

  return (
    <section className="py-5">
      <div className="container-fluid py-5">
        <div className="container-xxl p-0 p-lg-3">
          <div className="row mb-3 pb-1 pb-md-3 p-0 px-xxl-1">
            <div className="col d-flex flex-row justify-content-between align-items-center ">
              <h5 className="d-none d-sm-flex">
              {
              props.sectionHeadline
                ? props.sectionHeadline
                : " "
              }
              </h5>
              <h5 className="d-sm-none">
              {
              props.sectionHeadlineShort
                ? props.sectionHeadlineShort
                : " "
              }
              </h5>
              <Link className="arrow-button toggleColor" to="/startups/">
                <p className="mb-0 hover-show">
                  <i>
                    View all{" "}
                    <span className="d-none d-sm-inline">startups</span>
                  </i>
                </p>
                <img alt="arrow" src={arrow} className="mx-3" />
              </Link>
            </div>
          </div>
          <div className="container">
            <div className="row my-0 gx-4 gy-5 p-0 px-xxl-0 startup-logos-container d-flex align-items-center">
              {data.allMarkdownRemark.edges.map(({ node }, index) => (
                <div
                  key={index}
                  className="col-6 col-md-4 col-lg-3 text-center"
                >
                  <img
                    alt={node.frontmatter.title}
                    src={node.frontmatter.logo}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
