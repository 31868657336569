import * as React from "react"

import "./intro.module.scss"

import introTexts from "../../content/landing_page/intro_section.json"

const Intro = () => {
  return (
    <section className="intro-section pb-5">
      <div className="container-fluid intro-container text-white">
        <div className="intro-content pt-5 d-flex flex-column">
          <div className="container-xxl flex-grow-1 d-flex flex-column justify-content-center p-0 p-lg-3">
            <div className="row justify-content-center p-0 px-xxl-0">
              <div className="intro-text-container text-center col-12">
                <h1 className="">{introTexts.headline}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Intro
