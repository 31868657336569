import * as React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import "./success_stories.module.scss"
import arrow from "../images/arrow.svg"

export default function SuccessStories(props) {
  const data = useStaticQuery(graphql`
    query SuccessStoriesQuery {
      allMarkdownRemark(
        filter: { frontmatter: { tags: { eq: "Story" } } }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              author
              tags
              title
              description
              image
              cta_text
            }
            html
          }
        }
      }
    }
  `)

  return (
    <div className="container-xxl p-0 pt-3 px-xxl-1">
      <div className="row justify-content-end gx-sm-4">
        {data.allMarkdownRemark.edges.map(({ node }, index) => (
          <Link
            key={index}
            className="div-link col-sm-4 pt-3 d-flex flex-column text-item"
            to={node.fields.slug}
          >
            <img
              className="story_image global-image-style"
              alt={node.frontmatter.title}
              src={node.frontmatter.image}
            />
            <h3 className="mb-2 mb-sm-3">{node.frontmatter.title}</h3>
            <p className=" flex-fill">{node.frontmatter.description}</p>
            <div className="arrow-button toggleColor d-flex flex-row justify-content-start align-items-center mb-5">
              <p className="mb-0">
                {node.frontmatter.cta_text
                  ? node.frontmatter.cta_text
                  : "Read more"}
              </p>
              <img alt="arrow" className="mx-3 " src={arrow} width="36" />
            </div>
          </Link>
        ))}
      </div>
    </div>
  )
}
