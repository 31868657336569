import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Contact from "../components/contact"
import Seo from "../components/seo"
import Intro from "../components/intro"
import SectionNoBG from "../components/section_no_bg"
import SectionImageGrid from "../components/section_image_grid"
import StartupsActive from "../components/startups_active"
import SuccessStories from "../components/success_stories"
import Timeline from "../components/timeline"

import Fade from "react-reveal/Fade"

import arrow from "../images/arrow.svg"

import introTexts from "../../content/landing_page/team.json"
import contactTexts from "../../content/landing_page/contact.json"
import caseStudies from "../../content/landing_page/case_studies.json"
import startups from "../../content/landing_page/startups.json"
import members from "../../content/team/members.json"


function IndexPage() {

  const imageArray = members.persons;

  return (
    <Layout page="Home">
    <Seo />
    <Intro />
    <Fade bottom duration={1500} fraction={0.1} distance={"200px"}>
      <SectionImageGrid
        images = {imageArray}
        headline={introTexts.headline}
        text={introTexts.text}
      >
        <Link
          to="/team"
          className=" arrow-button mt-4 me-5 d-block d-md-inline-block"
        >
          <p className="mb-0 hover-show">{introTexts.button_text}</p>
          <img alt="arrow" className="mx-3" src={arrow} width="36" />
        </Link>
      </SectionImageGrid >
      <StartupsActive
        sectionHeadline ={ startups.section_headline}
        sectionHeadlineShort ={ startups.section_headline_short}
        headline ={ startups.headline} />
      <SectionNoBG
        sectionHeadline={caseStudies.section_headline}
        headline={caseStudies.headline}
      >
        <SuccessStories />
      </SectionNoBG>
      <Timeline />
      <Contact texts={contactTexts} />
    </Fade>
  </Layout>
  )
}

export default IndexPage
