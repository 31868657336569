import React, { useState, useRef, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useToggle } from "react-use"
import { useInView } from "react-hook-inview"
import Picker from "rmc-picker-scroll"
import "rmc-picker-scroll/assets/index.css"

import getFullsizeImage from "../utils/getFullsizeImage"
import "./section_image_bg.module.scss"
import "./timeline.module.scss"

const slideDuration = 16000

const resetAnimation = item => {
  item.current.style.animation = "none"
  void item.current.offsetWidth
  item.current.style.animation = null
  item.current.style.animationDuration = slideDuration + "ms"
}

function updatePickerStyles() {
  let opacityUp = 0.9
  let opacityDown = 0.9
  let current = document.getElementsByClassName("rmc-picker-item-selected")
  let nextSibling = current[0].nextElementSibling
  let previousSibling = current[0].previousElementSibling
  current[0].style.opacity = 1
  while (nextSibling) {
    nextSibling.style.opacity = opacityDown
    nextSibling = nextSibling.nextElementSibling
    opacityDown = opacityDown - 0.1
  }
  while (previousSibling) {
    previousSibling.style.opacity = opacityUp
    previousSibling = previousSibling.previousElementSibling
    opacityUp = opacityUp - 0.1
  }
}

export default function Timeline() {
  const data = useStaticQuery(graphql`
    query TimelineQuery {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(timeline)/" } }
        sort: { fields: frontmatter___date }
      ) {
        edges {
          node {
            frontmatter {
              headline
              text
              date(formatString: "YYYY")
              image
              image_alt_text
              video
              type
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    updatePickerStyles()
  }, [])

  const timelineEntries = data.allMarkdownRemark.edges
  const progressBar = useRef(null)
  const slideImage = useRef(null)
  const itemTexts = useRef(null)
  const [inViewRef, inView] = useInView()
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isRunning, toggleIsRunning] = useToggle(true)

  useEffect(() => {
    updatePickerStyles()

    if (isRunning && inView) {
      resetAnimation(progressBar)
      resetAnimation(slideImage)
      resetAnimation(itemTexts)

      const interval = setInterval(() => {
        setCurrentIndex(
          currentIndex === timelineEntries.length - 1 ? 0 : currentIndex + 1
        )
      }, slideDuration)
      return () => clearInterval(interval)
    }

    return undefined
  }, [currentIndex, isRunning, inView])

  const activeItem = timelineEntries[currentIndex]

  var videoBg = false
  var imageBg = false
  var smallImage = false

  if (activeItem.node.frontmatter.type === "bg_video") {
    videoBg = true
  } else if (activeItem.node.frontmatter.type === "bg_image") {
    imageBg = true
  } else {
    smallImage = true
  }

  return (
    <div>
      <section className="image-section timeline py-5" ref={inViewRef}>
        <div className="container-fluid image-section-container text-white p-0">
          <div className="before"></div>
          {imageBg && (
            <div ref={slideImage} className="image-slider">
              <img
                src={getFullsizeImage(activeItem.node.frontmatter.image)}
                alt={
                  activeItem.node.frontmatter.image_alt_text
                    ? `${activeItem.node.frontmatter.image_alt_text}`
                    : ``
                }
              />
            </div>
          )}
          {videoBg && (
            <div
              ref={slideImage}
              className="image-slider"
              style={{
                animation: "none"
              }}
            >
              <video
                key={activeItem.node.frontmatter.video}
                playsInline
                autoPlay
                muted
                loop
              >
                <source
                  src={`${activeItem.node.frontmatter.video.replace(
                    /\.mp4$/,
                    ".webm"
                  )}`}
                  type="video/webm"
                />
                <source
                  src={activeItem.node.frontmatter.video}
                  type="video/mp4"
                />
              </video>
            </div>
          )}
          {smallImage && (
            <>
              <div ref={slideImage} className="image-slider blurry">
                <img
                  src={getFullsizeImage(activeItem.node.frontmatter.image)}
                  alt={
                    activeItem.node.frontmatter.image_alt_text
                      ? `${activeItem.node.frontmatter.image_alt_text}`
                      : ``
                  }
                />
              </div>
              <div ref={slideImage} className="image-slider">
                <div className="small-image">
                  <img
                    src={activeItem.node.frontmatter.image}
                    alt={
                      activeItem.node.frontmatter.image_alt_text
                        ? `${activeItem.node.frontmatter.image_alt_text}`
                        : ``
                    }
                  />
                </div>
              </div>
            </>
          )}

          <div className="image-section-content p-0 d-flex flex-row">
            <div className="timeline-control d-flex flex-column align-items-center">
              <div className="years-wrapper flex-grow-1 d-flex flex-column justify-content-center">
                <Picker
                  onValueChange={setCurrentIndex}
                  selectedValue={currentIndex}
                >
                  {timelineEntries.map((entry, i) => (
                    <Picker.Item key={i} className="year" value={i}>
                      {entry.node.frontmatter.date}
                    </Picker.Item>
                  ))}
                </Picker>
              </div>
              <p
                className="timeline-pause-display m-0 py-3"
                onClick={toggleIsRunning}
              >
                {isRunning ? "pause" : "start"}
              </p>
            </div>
            <div className="timeline-item-and-progress flex-column d-flex align-items-start">
              <div
                ref={itemTexts}
                className="timeline-item-texts flex-grow-1 d-flex align-items-center"
              >
                <div className="d-flex flex-column p-3 p-md-5 pb-0">
                  <h5 className="mb-3 dimmed-text">
                    {activeItem.node.frontmatter.date}
                  </h5>
                  <h2 className="mb-3">
                    {activeItem.node.frontmatter.headline}
                  </h2>
                  <p className="lead">{activeItem.node.frontmatter.text}</p>
                </div>
              </div>
              <div className="timeline-progress container-fluid p-3 p-md-5 pb-3 pb-md-3">
                <div
                  className="progress my-3"
                  style={{
                    height: "1px",
                    visibility: isRunning ? "visible" : "hidden"
                  }}
                >
                  <div
                    ref={progressBar}
                    id="myBar"
                    className="progress-bar"
                    role="progressbar"
                    aria-label="Progress bar"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
