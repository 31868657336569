import React, { useEffect } from "react"
import getFullsizeImage from "../utils/getFullsizeImage"

import "./section_image_grid.module.scss"
import "./section_image_bg.module.scss"

function SectionImageGrid(props) {

  var [randomPersons, setRandomPersons] = React.useState([]);

  useEffect(() => {
    setRandomPersons([...props.images.slice(0,18)].sort(() => Math.random() - 0.5));
  }, []);

  return (
    <section
      id={props.id}
      className={
        props.classes ? `image-section ${props.classes}` : `image-section`
      }
    >
      <div className="container-fluid image-section-container text-white">
        <div className="image-slider">
          <div className="grid-container">
            {randomPersons.map((pic, index) => (
               <div key={`${index}-${pic.profilePicture}`}>
                  <img src={getFullsizeImage(pic.profilePicture)} alt="{index}"/>
              </div>
            ))}
          </div>
        </div>
        <div className="image-section-content d-flex flex-column">
          <div className="container-xxl flex-grow-1 d-flex flex-column justify-content-center">
            <div className="row justify-content-start">
              <div className="col-12">
                {props.sectionHeadline && (
                  <h5 className="mb-4">{props.sectionHeadline}</h5>
                )}
                {props.headline && <h1 className=" mb-4">{props.headline}</h1>}
                {props.content ? (
                  <p
                    className="lead mt-3 col-12 col-md-7"
                    dangerouslySetInnerHTML={{ __html: props.content }}
                  />
                ) : (
                  <p className="lead mt-3 col-12 col-md-7">{props.text}</p>
                )}
              </div>
            </div>
            {props.children}
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionImageGrid
